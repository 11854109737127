<template>
  <div class="container mt-4">
    <h4>Logging out</h4>
    <hr />
    <p>Please wait...</p>
  </div>
</template>

<script>
export default {
  middleware: (ctx) => {
    if (process.server) {
      return ctx.$auth.logout().then(() => {
        return ctx.redirect(302, '/')
      })
    }
  },
  mounted() {
    return this.$auth.logout().then(() => {
      window.location.replace('/')
    })
  }
}
</script>
